.l-header-logo {
  width: 142px;
  position: absolute;
  top: 0;
  left: 0;

  @include media-breakpoint-up(md) {
    width: 330px;
    min-width: 330px;
  }

  &__link {
    display: block;
  }

  &__img {}
}
