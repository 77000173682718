@import "header-logo";
@import "header-read";
@import "header-nav";
@import "header-hamburger-btn";

.l-header {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 10;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  @include media-breakpoint-up(xl) {
    height: 60px;
  }

  @include media-breakpoint-down(xl) {
    height: 72px;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: calc(100% - 330px);
    background-color: white;
    justify-content: space-between;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-down(xl) {
      align-items: flex-end;
      flex-direction: column;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      align-items: center;
    }
  }
}
