.p-top-voice {
  padding-top: 40px;
  padding-bottom: 70px;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 140px;
  }

  &__h3 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(37 / 24);

    @include media-breakpoint-up(md) {
      width: 416px;
      font-size: 3.8rem;
      line-height: calc(58 / 38);
    }
  }

  &__section {
    width: 100%;
    padding: 0 20px 60px;

    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: $base-width;
      align-self: center;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 20px;
      padding-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }

    &.-section01 {
      padding-bottom: 56px;

      @include media-breakpoint-up(md) {
        padding-bottom: 154px;
      }
    }

    &.-section02 {
      padding-top: 29px;
      padding-bottom: 63px;

      @include media-breakpoint-up(md) {
        padding: 75px 20px 93px;
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 90px;

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      align-items: center;
      justify-content: center;
    }
  }

  &__wrapper {
    @include media-breakpoint-up(md) {
      width: 710px;
    }
  }

  &__image {
    width: 180px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  &__text {

    &:not(:last-of-type) {
      margin-bottom: 28px;

      @include media-breakpoint-up(md) {
        margin-bottom: 36px;
      }
    }

    dt {
      font-size: 20px;
      line-height: 2;
      margin-bottom: 10px;
      float: left;

      @include media-breakpoint-down(sm) {
        float: none;
        font-size: 18px;
      }
    }

    dd {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 80px;
      position: relative;
      padding-left: 30px;
      margin-left: auto;
      float: right;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        margin-bottom: 40px;
        float: none;
        text-align: right;
        line-height: 1.5;
      }

      &::before {
        content: '';
        position: absolute;
        background: #af0821;
        height: 1px;
        width: 24px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__list {
    padding-top: 27px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column;

    &__inner {
      width: 100%;
      padding: 0 20px 80px;

      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: $base-width;
        align-self: center;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 20px;
      }
    }

    &__text {
      font-size: 1.5rem;
      margin-top: 15px;
      margin-bottom: 60px;
      text-align: center;
    }
  }
}
