.c-top-ttl {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: 400px;
  }

  &__bg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.__app {
      background-image: url("/assets/img/app/kv.jpg");

      @include media-breakpoint-down(sm) {
        background-image: url("/assets/img/app/kv_sp.jpg");
      }
    }

    &.__rep {
      background-image: url("/assets/img/report/kv.jpg");

      @include media-breakpoint-down(sm) {
        background-image: url("/assets/img/report/kv_sp.jpg");
      }
    }

    &.__adv {
      background-image: url("/assets/img/advance/kv.jpg");

      @include media-breakpoint-down(sm) {
        background-image: url("/assets/img/advance/kv_sp.jpg");
      }
    }
  }

  &__title-group {
    width: 100%;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%);

    @include media-breakpoint-up(md) {
      top: 41.2%;
    }

    &__img {}
  }

  &__title {
    width: 100%;
    /* stylelint-disable-next-line */
    font-family: Roboto;
    font-style: italic;
    font-size: calc(100vw / 48.5 * 5);
    font-weight: bold;
    color: white;
    line-height: 1;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      margin-bottom: 7px;
      text-align: center;
    }

    @media screen and (max-width: 320px) {
      font-size: 3.2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 6.8rem;
      text-align: center;
    }

    &.__small {
      font-size: 1.8rem;
      font-style: normal;
    }

    .anim-band__text {
      padding-bottom: 9px;

      @include media-breakpoint-up(md) {
        padding-bottom: 15px;
      }
    }
  }

  &__read {
    font-size: 1.6rem;
    font-weight: bold;
    white-space: nowrap;
    color: white;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.75s;
    padding-left: 3px;

    @media screen and (max-width: 320px){
      font-size: 1.4rem;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    &.-active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__to-detail-link {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      position: relative;
      border: solid 1px white;
      padding: 5px 10px;
    }

    @include media-breakpoint-up(md) {
      align-items: center;
      justify-content: center;
      padding-right: 20px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      background-color: $theme-color;
      position: relative;
      opacity: 1;
      padding: 20px;

      @include media-breakpoint-up(md) {
        width: 358px;
        height: 80px;
      }
    }

    &__text {
      font-size: 1.4rem;
      color: white;
      line-height: 1.7;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }

      &.-small {
        font-size: 1.2rem;

        @include media-breakpoint-up(md) {
          font-size: 1.4rem;
        }
      }
    }

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
    }

    &::before {
      width: 0;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.2);
      transition: width 0.3s;
      pointer-events: none;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::after {
      width: 9px;
      height: 24px;
      top: 50%;
      right: 5%;
      background: url(/assets/img/common/icon_arrow-bottom.svg) no-repeat center;
      background-size: contain;

      @include media-breakpoint-up(md) {
        width: 6px;
        height: 16px;
        top: 50%;
        transform: translateX(0) translateY(-50%) rotate(-90deg);
        transition: transform 0.3s;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &:hover::before {
      width: 100%;
    }

    &:hover::after {
      @include media-breakpoint-up(md) {
        transform: translateX(7px) translateY(-50%) rotate(-90deg);
      }
    }
  }
}
