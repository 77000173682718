.p-top-end-message {
  display: flex;
  justify-content: center;
  padding-top: 13px;

  @include media-breakpoint-up(md) {
    padding-top: 25px;
  }

  &__text {
    width: 100%;
    max-width: $base-width;
    font-size: 1.2rem;
    margin: 0 20px;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }
}
