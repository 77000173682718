.c-heading {

  &__h2 {
    font-size: 2.4rem;
    font-weight: bold;
    color: $theme-color;
    line-height: calc(37 / 24);
    border-bottom: solid 1px $theme-color;
    padding-bottom: 10px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 3.8rem;
      line-height: 1.5;
      padding-bottom: 24px;
    }
  }
}
