.l-drawer-menu {
  width: 100%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  z-index: 15;

  @include media-breakpoint-up(md) {
    display: none !important;
  }

  &__nav {
    width: 100%;
    position: relative;
    padding: 0 23px;
    margin-top: 99px;
  }

  &__list {
    height: 278px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 15px;
  }

  &__item {
    position: relative;
    padding-left: 32px;

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 1px;
      background-color: $theme-color;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  &__link {
    font-size: 1.8rem;

    @media screen and (max-width: 320px) {
      font-size: 1.4rem;
    }
  }

  &__coming-soon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 37px;
    right: 37px;
    background-color: rgba(175, 8, 8, 0.65);
    font-size: 2.4rem;
    color: white;
  }

  &__annot {
    padding: 0 37px;

    @media screen and (max-width: 320px) {
      font-size: 1.3rem;
    }
  }

  body[data-is-visible-drawer="true"] & {
    opacity: 1;
    visibility: visible;
  }
}
