.l-pageup {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  opacity: 0;
  margin-bottom: 4px;

  @include media-breakpoint-up(md) {
    width: 80px;
    margin-right: 4px;
    margin-bottom: 0;

    &:hover {
      opacity: 0.75 !important;
    }
  }

  &__img {
  }

  body[data-is-visible-pageup="true"] & {
    opacity: 1;
    visibility: visible;
  }
}

.l-float {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  position: fixed;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
    flex-direction: row;
  }

  &.-static {
    position: static;
    bottom: inherit;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }
  }
}
