@charset "UTF-8";
/* google fonts */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700|Roboto:400,700,700i&display=swap");
/* core */
/* bootstrapのutilitiesクラスだけ使用する */
/* libs */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #af0821 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #7e0618 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #af0821 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1281px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1281px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1281px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1281px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1281px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #af0821 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #660513 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* =============================================
 * Base
 * ============================================= */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0; }

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-family: "Roboto", "Noto Sans JP", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 2;
  min-width: 320px;
  min-height: 100%; }
  @media (min-width: 769px) {
    body {
      min-width: 1000px;
      font-size: 1.8rem; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

ul,
ol {
  list-style-type: none; }

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: top; }

a {
  color: #333;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.35s;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }
  a img {
    border: none; }
  a:hover {
    color: #333; }

*,
*::before,
*::after {
  box-sizing: border-box; }

/* layouts */
.l-header-logo {
  width: 142px;
  position: absolute;
  top: 0;
  left: 0; }
  @media (min-width: 769px) {
    .l-header-logo {
      width: 330px;
      min-width: 330px; } }
  .l-header-logo__link {
    display: block; }

.l-header-read {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0 22px; }

@media (max-width: 1024.98px) {
  .l-header-nav {
    width: 100%; } }

.l-header-nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 22px; }
  @media (min-width: 1281px) {
    .l-header-nav__list {
      margin-right: 60px; } }

.l-header-nav__item {
  margin-right: 30px; }
  .l-header-nav__item:last-child {
    margin-right: 0; }

.l-header-nav__link {
  position: relative;
  font-size: 1.4rem;
  padding-left: 40px;
  font-weight: bold; }
  .l-header-nav__link::before {
    content: "";
    position: absolute;
    background: #af0821;
    height: 1px;
    width: 34px;
    top: 50%;
    left: 0; }
  .l-header-nav__link:hover {
    opacity: 0.65; }

.l-header-hamburger-btn {
  width: 80px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20; }
  @media (min-width: 769px) {
    .l-header-hamburger-btn {
      display: none; } }
  .l-header-hamburger-btn__bar {
    width: 39px;
    height: 3px;
    position: relative;
    background-color: #af0821;
    transition: all 0.3s; }
    .l-header-hamburger-btn__bar::before, .l-header-hamburger-btn__bar::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: #af0821;
      transition: all 0.3s; }
    .l-header-hamburger-btn__bar::before {
      top: -12px; }
    .l-header-hamburger-btn__bar::after {
      top: 12px; }
  body[data-is-visible-drawer="true"] .l-header-hamburger-btn .l-header-hamburger-btn__bar {
    width: 48px;
    background-color: transparent !important; }
    body[data-is-visible-drawer="true"] .l-header-hamburger-btn .l-header-hamburger-btn__bar::before {
      top: 0;
      transform: rotate(225deg); }
    body[data-is-visible-drawer="true"] .l-header-hamburger-btn .l-header-hamburger-btn__bar::after {
      top: 0;
      transform: rotate(-225deg); }

.l-header {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
  height: 72px; }
  @media (max-width: 768.98px) {
    .l-header {
      height: auto; } }
  @media (min-width: 1281px) {
    .l-header {
      height: 60px; } }
  .l-header__inner {
    display: flex;
    align-items: center;
    width: calc(100% - 330px);
    background-color: white;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    flex-direction: column; }
    @media (max-width: 768.98px) {
      .l-header__inner {
        display: none; } }
    @media (min-width: 1281px) {
      .l-header__inner {
        flex-direction: row;
        align-items: center; } }

.l-footer-kv {
  height: 384px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("/assets/img/common/footer-kv-sp.jpg"); }
  @media (max-width: 768.98px) {
    .l-footer-kv {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .l-footer-kv {
      height: 446px;
      background-image: url("/assets/img/common/footer-kv.jpg");
      background-size: auto 100%;
      background-color: black; } }
  .l-footer-kv__top-area {
    height: 174px;
    background-color: rgba(0, 0, 0, 0.25); }
    @media (min-width: 769px) {
      .l-footer-kv__top-area {
        height: 260px;
        padding-top: 100px; } }
  .l-footer-kv__bottom-area {
    height: 210px;
    background-color: rgba(175, 8, 8, 0.6); }
    @media (min-width: 769px) {
      .l-footer-kv__bottom-area {
        height: 186px;
        padding-top: 40px; } }
  .l-footer-kv__title {
    width: 698px;
    font-size: 6.9rem;
    font-weight: bold;
    font-style: italic;
    color: white;
    line-height: 1;
    text-shadow: 0 4px 4px rgba(255, 255, 255, 0.16);
    white-space: nowrap;
    margin: 0 auto; }
    @media (max-width: 768.98px) {
      .l-footer-kv__title {
        display: none; } }
  .l-footer-kv__annot {
    font-size: 1.2rem;
    color: white; }
    @media screen and (max-width: 320px) {
      .l-footer-kv__annot {
        font-size: 1rem; } }
    @media (min-width: 769px) {
      .l-footer-kv__annot {
        font-size: 1.6rem;
        text-align: center;
        padding: 0 20px; } }

.l-footer-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 267px;
  background-color: rgba(255, 255, 255, 0.8); }
  @media (max-width: 768.98px) {
    .l-footer-nav {
      padding: 33px 0 35px;
      margin: -134px 0 14px; } }
  @media (min-width: 769px) {
    .l-footer-nav {
      align-items: center;
      width: 1000px;
      height: 75px;
      margin: 0 auto 20px; } }
  @media (max-width: 768.98px) {
    .l-footer-nav__list {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  @media (min-width: 769px) {
    .l-footer-nav__list {
      display: flex; } }
  .l-footer-nav__item {
    position: relative;
    padding-left: 30px; }
    @media (min-width: 769px) {
      .l-footer-nav__item {
        padding-left: 30px; }
        .l-footer-nav__item:not(:last-of-type) {
          margin-right: 10px; } }
    .l-footer-nav__item::before {
      display: block;
      content: "";
      width: 24px;
      height: 1px;
      background-color: #af0821;
      position: absolute;
      top: 50%;
      left: 0; }
      @media (min-width: 769px) {
        .l-footer-nav__item::before {
          width: 24px; } }
  .l-footer-nav__link {
    font-size: 1.4rem;
    font-weight: bold;
    color: #000; }

.l-footer-copyright {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold; }
  @media (min-width: 769px) {
    .l-footer-copyright {
      font-size: 1.2rem; } }

.l-footer {
  position: relative; }

/* =============================================
 * Main
 * ============================================= */
.l-main {
  flex-grow: 1;
  padding-bottom: 40px; }
  @media (min-width: 769px) {
    .l-main {
      padding-bottom: 100px; } }

.l-drawer-menu {
  width: 100%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  z-index: 15; }
  @media (min-width: 769px) {
    .l-drawer-menu {
      display: none !important; } }
  .l-drawer-menu__nav {
    width: 100%;
    position: relative;
    padding: 0 23px;
    margin-top: 99px; }
  .l-drawer-menu__list {
    height: 278px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 15px; }
  .l-drawer-menu__item {
    position: relative;
    padding-left: 32px; }
    .l-drawer-menu__item::before {
      display: block;
      content: "";
      width: 24px;
      height: 1px;
      background-color: #af0821;
      position: absolute;
      top: 50%;
      left: 0; }
  .l-drawer-menu__link {
    font-size: 1.8rem; }
    @media screen and (max-width: 320px) {
      .l-drawer-menu__link {
        font-size: 1.4rem; } }
  .l-drawer-menu__coming-soon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 37px;
    right: 37px;
    background-color: rgba(175, 8, 8, 0.65);
    font-size: 2.4rem;
    color: white; }
  .l-drawer-menu__annot {
    padding: 0 37px; }
    @media screen and (max-width: 320px) {
      .l-drawer-menu__annot {
        font-size: 1.3rem; } }
  body[data-is-visible-drawer="true"] .l-drawer-menu {
    opacity: 1;
    visibility: visible; }

.l-overlay {
  width: 100%;
  height: 200%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  visibility: hidden;
  opacity: 0;
  will-change: opacity;
  transition: all 0.3s;
  z-index: 10; }
  body[data-is-visible-drawer="true"] .l-overlay {
    visibility: visible;
    opacity: 1; }

.l-breadcrumb {
  width: 100%;
  max-width: 1000px;
  margin: 10px auto 0; }
  @media (max-width: 768.98px) {
    .l-breadcrumb {
      padding: 0 20px; } }
  .l-breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .l-breadcrumb__item {
    display: flex;
    align-items: center; }
    .l-breadcrumb__item:not(:last-of-type) {
      margin-right: 5px; }
      .l-breadcrumb__item:not(:last-of-type)::after {
        content: ">";
        font-size: 1.2rem;
        margin-left: 5px; }
    .l-breadcrumb__item a {
      text-decoration: underline; }
  .l-breadcrumb__link {
    font-size: 1.2rem;
    white-space: nowrap; }

.l-pageup {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  opacity: 0;
  margin-bottom: 4px; }
  @media (min-width: 769px) {
    .l-pageup {
      width: 80px;
      margin-right: 4px;
      margin-bottom: 0; }
      .l-pageup:hover {
        opacity: 0.75 !important; } }
  body[data-is-visible-pageup="true"] .l-pageup {
    opacity: 1;
    visibility: visible; }

.l-float {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  position: fixed;
  width: 100%; }
  @media (min-width: 769px) {
    .l-float {
      width: auto;
      flex-direction: row; } }
  .l-float.-static {
    position: static;
    bottom: inherit;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .l-float.-static {
        margin-bottom: 20px; } }

/* components */
.c-download-btn {
  width: 100%;
  height: 61px;
  display: flex;
  align-items: center;
  position: relative;
  border: solid 2px #333;
  font-size: 1.5rem;
  padding: 0 50px 0 22px;
  transition: opacity 0.3s; }
  @media (min-width: 769px) {
    .c-download-btn {
      width: 280px;
      padding-left: 20px; } }
  .c-download-btn:hover {
    opacity: 0.75; }
  .c-download-btn::after {
    display: block;
    content: "";
    width: 25px;
    height: 32px;
    position: absolute;
    top: calc(50% - 15px);
    right: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
    @media (min-width: 769px) {
      .c-download-btn::after {
        right: 15px; } }
  .c-download-btn.-doc::after {
    background-image: url("/assets/img/common/icon_doc.svg"); }
  .c-download-btn.-pdf::after {
    background-image: url("/assets/img/common/icon_pdf.svg"); }
  @media (min-width: 769px) {
    .c-download-btn__group {
      display: flex; } }
  @media (max-width: 768.98px) {
    .c-download-btn__group .c-download-btn:not(:last-of-type) {
      margin-bottom: 15px; } }
  @media (min-width: 769px) {
    .c-download-btn__group .c-download-btn:not(:last-of-type) {
      margin-right: 20px; } }

.c-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  .c-col-03__item {
    width: 100%; }
    @media (min-width: 769px) {
      .c-col-03__item {
        width: 31.25%; } }

.c-link-btn {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.8rem;
  line-height: 1.3888;
  font-weight: bold;
  padding: 14px 30px 14px 14px;
  transition: opacity 0.3s;
  background: #af0821;
  color: #fff;
  text-align: center; }
  .c-link-btn-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px; }
  .c-link-btn:hover {
    color: #fff;
    opacity: 0.65; }
  @media (min-width: 769px) {
    .c-link-btn {
      width: 360px;
      padding-right: 30px; } }
  .c-link-btn::before, .c-link-btn::after {
    display: block;
    content: "";
    position: absolute; }
  .c-link-btn::before {
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: width 0.3s;
    pointer-events: none; }
    @media (max-width: 768.98px) {
      .c-link-btn::before {
        display: none; } }
  .c-link-btn::after {
    width: 9px;
    height: 24px;
    top: 50%;
    right: 5%;
    background: url(/assets/img/common/icon_arrow-bottom.svg) no-repeat center;
    background-size: contain;
    transform: translateX(0) translateY(-50%) rotate(-90deg); }
    @media (min-width: 769px) {
      .c-link-btn::after {
        width: 6px;
        height: 16px;
        top: 50%;
        transition: transform 0.3s; } }
  .c-link-btn:hover::before {
    width: 100%; }
  @media (min-width: 769px) {
    .c-link-btn:hover::after {
      transform: translateX(7px) translateY(-50%) rotate(-90deg); } }

.c-top-ttl {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 769px) {
    .c-top-ttl {
      height: 400px; } }
  .c-top-ttl__bg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    .c-top-ttl__bg.__app {
      background-image: url("/assets/img/app/kv.jpg"); }
      @media (max-width: 768.98px) {
        .c-top-ttl__bg.__app {
          background-image: url("/assets/img/app/kv_sp.jpg"); } }
    .c-top-ttl__bg.__rep {
      background-image: url("/assets/img/report/kv.jpg"); }
      @media (max-width: 768.98px) {
        .c-top-ttl__bg.__rep {
          background-image: url("/assets/img/report/kv_sp.jpg"); } }
    .c-top-ttl__bg.__adv {
      background-image: url("/assets/img/advance/kv.jpg"); }
      @media (max-width: 768.98px) {
        .c-top-ttl__bg.__adv {
          background-image: url("/assets/img/advance/kv_sp.jpg"); } }
  .c-top-ttl__title-group {
    width: 100%;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%); }
    @media (min-width: 769px) {
      .c-top-ttl__title-group {
        top: 41.2%; } }
  .c-top-ttl__title {
    width: 100%;
    /* stylelint-disable-next-line */
    font-family: Roboto;
    font-style: italic;
    font-size: calc(100vw / 48.5 * 5);
    font-weight: bold;
    color: white;
    line-height: 1;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    white-space: nowrap; }
    @media (max-width: 768.98px) {
      .c-top-ttl__title {
        margin-bottom: 7px;
        text-align: center; } }
    @media screen and (max-width: 320px) {
      .c-top-ttl__title {
        font-size: 3.2rem; } }
    @media (min-width: 769px) {
      .c-top-ttl__title {
        font-size: 6.8rem;
        text-align: center; } }
    .c-top-ttl__title.__small {
      font-size: 1.8rem;
      font-style: normal; }
    .c-top-ttl__title .anim-band__text {
      padding-bottom: 9px; }
      @media (min-width: 769px) {
        .c-top-ttl__title .anim-band__text {
          padding-bottom: 15px; } }
  .c-top-ttl__read {
    font-size: 1.6rem;
    font-weight: bold;
    white-space: nowrap;
    color: white;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.75s;
    padding-left: 3px; }
    @media screen and (max-width: 320px) {
      .c-top-ttl__read {
        font-size: 1.4rem; } }
    @media (min-width: 769px) {
      .c-top-ttl__read {
        display: none; } }
    .c-top-ttl__read.-active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0); }
  .c-top-ttl__to-detail-link {
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768.98px) {
      .c-top-ttl__to-detail-link {
        position: relative;
        border: solid 1px white;
        padding: 5px 10px; } }
    @media (min-width: 769px) {
      .c-top-ttl__to-detail-link {
        align-items: center;
        justify-content: center;
        padding-right: 20px; } }
    .c-top-ttl__to-detail-link__wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      background-color: #af0821;
      position: relative;
      opacity: 1;
      padding: 20px; }
      @media (min-width: 769px) {
        .c-top-ttl__to-detail-link__wrapper {
          width: 358px;
          height: 80px; } }
    .c-top-ttl__to-detail-link__text {
      font-size: 1.4rem;
      color: white;
      line-height: 1.7;
      text-align: center; }
      @media (min-width: 769px) {
        .c-top-ttl__to-detail-link__text {
          font-size: 1.8rem; } }
      .c-top-ttl__to-detail-link__text.-small {
        font-size: 1.2rem; }
        @media (min-width: 769px) {
          .c-top-ttl__to-detail-link__text.-small {
            font-size: 1.4rem; } }
    .c-top-ttl__to-detail-link::before, .c-top-ttl__to-detail-link::after {
      display: block;
      content: "";
      position: absolute; }
    .c-top-ttl__to-detail-link::before {
      width: 0;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.2);
      transition: width 0.3s;
      pointer-events: none; }
      @media (max-width: 768.98px) {
        .c-top-ttl__to-detail-link::before {
          display: none; } }
    .c-top-ttl__to-detail-link::after {
      width: 9px;
      height: 24px;
      top: 50%;
      right: 5%;
      background: url(/assets/img/common/icon_arrow-bottom.svg) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .c-top-ttl__to-detail-link::after {
          width: 6px;
          height: 16px;
          top: 50%;
          transform: translateX(0) translateY(-50%) rotate(-90deg);
          transition: transform 0.3s; } }
      @media (max-width: 768.98px) {
        .c-top-ttl__to-detail-link::after {
          display: none; } }
    .c-top-ttl__to-detail-link:hover::before {
      width: 100%; }
    @media (min-width: 769px) {
      .c-top-ttl__to-detail-link:hover::after {
        transform: translateX(7px) translateY(-50%) rotate(-90deg); } }

.c-heading__h2 {
  font-size: 2.4rem;
  font-weight: bold;
  color: #af0821;
  line-height: calc(37 / 24);
  border-bottom: solid 1px #af0821;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  @media (min-width: 769px) {
    .c-heading__h2 {
      font-size: 3.8rem;
      line-height: 1.5;
      padding-bottom: 24px; } }

/* pages */
.p-top-kv {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 769px) {
    .p-top-kv {
      height: calc(100vh - 60px);
      min-height: 400px; } }
  .p-top-kv__bg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("/assets/img/top/kv-sp.jpg"); }
    @media (min-width: 769px) {
      .p-top-kv__bg {
        background-image: url("/assets/img/top/kv.jpg"); } }
  .p-top-kv__title-group {
    width: auto;
    position: absolute;
    top: 20.5%;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 769px) {
      .p-top-kv__title-group {
        top: 41.2%; } }
  .p-top-kv__title {
    width: 100%;
    /* stylelint-disable-next-line */
    font-family: Roboto;
    font-style: italic;
    font-size: calc(100vw / 37.5 * 5);
    font-weight: bold;
    color: white;
    line-height: 1;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    white-space: nowrap; }
    @media (max-width: 768.98px) {
      .p-top-kv__title {
        margin-bottom: 7px; } }
    @media screen and (max-width: 320px) {
      .p-top-kv__title {
        font-size: 4rem; } }
    @media (min-width: 769px) {
      .p-top-kv__title {
        font-size: 8.8rem; } }
    .p-top-kv__title .anim-band__text {
      padding-bottom: 9px; }
      @media (min-width: 769px) {
        .p-top-kv__title .anim-band__text {
          padding-bottom: 15px; } }
  .p-top-kv__read {
    font-size: 1.6rem;
    font-weight: bold;
    white-space: nowrap;
    color: white;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.75s;
    padding-left: 3px; }
    @media screen and (max-width: 320px) {
      .p-top-kv__read {
        font-size: 1.4rem; } }
    @media (min-width: 769px) {
      .p-top-kv__read {
        display: none; } }
    .p-top-kv__read.-active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0); }
  .p-top-kv__to-detail-link {
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768.98px) {
      .p-top-kv__to-detail-link {
        position: relative;
        border: solid 1px white;
        padding: 5px 10px; } }
    @media (min-width: 769px) {
      .p-top-kv__to-detail-link {
        align-items: center;
        justify-content: center;
        padding-right: 20px; } }
    .p-top-kv__to-detail-link__wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      background-color: #af0821;
      position: relative;
      opacity: 1;
      padding: 20px; }
      @media (min-width: 769px) {
        .p-top-kv__to-detail-link__wrapper {
          width: 358px;
          height: 80px; } }
    .p-top-kv__to-detail-link__text {
      font-size: 1.4rem;
      color: white;
      line-height: 1.7;
      text-align: center; }
      @media (min-width: 769px) {
        .p-top-kv__to-detail-link__text {
          font-size: 1.8rem; } }
      .p-top-kv__to-detail-link__text.-small {
        font-size: 1.2rem; }
        @media (min-width: 769px) {
          .p-top-kv__to-detail-link__text.-small {
            font-size: 1.4rem; } }
    .p-top-kv__to-detail-link::before, .p-top-kv__to-detail-link::after {
      display: block;
      content: "";
      position: absolute; }
    .p-top-kv__to-detail-link::before {
      width: 0;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.2);
      transition: width 0.3s;
      pointer-events: none; }
      @media (max-width: 768.98px) {
        .p-top-kv__to-detail-link::before {
          display: none; } }
    .p-top-kv__to-detail-link::after {
      width: 9px;
      height: 24px;
      top: 50%;
      right: 5%;
      background: url(/assets/img/common/icon_arrow-bottom.svg) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .p-top-kv__to-detail-link::after {
          width: 6px;
          height: 16px;
          top: 50%;
          transform: translateX(0) translateY(-50%) rotate(-90deg);
          transition: transform 0.3s; } }
      @media (max-width: 768.98px) {
        .p-top-kv__to-detail-link::after {
          display: none; } }
    .p-top-kv__to-detail-link:hover::before {
      width: 100%; }
    @media (min-width: 769px) {
      .p-top-kv__to-detail-link:hover::after {
        transform: translateX(7px) translateY(-50%) rotate(-90deg); } }

.p-top-message {
  padding-top: 61px; }
  @media (min-width: 769px) {
    .p-top-message {
      display: flex;
      flex-direction: column;
      padding-top: 108px; } }
  .p-top-message__h2 {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    color: #af0821;
    line-height: 28px;
    letter-spacing: 2.4px; }
    @media (min-width: 769px) {
      .p-top-message__h2 {
        font-size: 2.4rem;
        line-height: 28px;
        letter-spacing: 2.4px; } }
    .p-top-message__h2.anim-band-simple.-active {
      width: 155px; }
    .p-top-message__h2__wrapper {
      align-self: center;
      width: 100%;
      max-width: 1000px;
      padding: 0 20px;
      margin-bottom: 29px; }
      @media (min-width: 769px) {
        .p-top-message__h2__wrapper {
          margin-bottom: 64px; } }
    .p-top-message__h2__text {
      display: block;
      border-left: solid 3px #af0821;
      padding-left: 18px; }
      @media (min-width: 769px) {
        .p-top-message__h2__text {
          padding-left: 19px; } }
  .p-top-message__h3 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(37 / 24); }
    @media (max-width: 768.98px) {
      .p-top-message__h3 {
        margin-bottom: 25px; } }
    @media (min-width: 769px) {
      .p-top-message__h3 {
        width: 416px;
        font-size: 3.8rem;
        line-height: calc(58 / 38);
        padding-top: 4px; } }
  .p-top-message__section {
    width: 100%;
    padding: 0 20px; }
    @media (min-width: 769px) {
      .p-top-message__section {
        width: 100%;
        max-width: 1000px;
        align-self: center;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 20px; } }
    .p-top-message__section.-section01 {
      padding-bottom: 56px; }
      @media (min-width: 769px) {
        .p-top-message__section.-section01 {
          padding-bottom: 154px; } }
    .p-top-message__section.-section02 {
      padding-top: 29px; }
      @media (min-width: 769px) {
        .p-top-message__section.-section02 {
          padding: 75px 20px 93px; } }
  @media (min-width: 769px) {
    .p-top-message__body {
      width: 480px; } }
  .p-top-message__text:not(:last-of-type) {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-top-message__text:not(:last-of-type) {
        margin-bottom: 36px; } }
  .p-top-message__text.__small {
    font-size: 1.4rem; }
    @media (max-width: 768.98px) {
      .p-top-message__text.__small {
        font-size: 1.2rem; } }
  .p-top-message__list {
    padding-top: 27px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768.98px) {
      .p-top-message__list {
        padding-bottom: 70px; } }
    .p-top-message__list__inner {
      width: 100%;
      padding: 0 20px 80px; }
      @media (min-width: 769px) {
        .p-top-message__list__inner {
          width: 100%;
          max-width: 1000px;
          align-self: center;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0 20px; } }
      @media (max-width: 768.98px) {
        .p-top-message__list__inner {
          margin-top: 5px;
          padding-bottom: 40px; } }
    .p-top-message__list__text {
      font-size: 1.5rem;
      margin-top: 15px;
      margin-bottom: 60px;
      text-align: center; }
      @media (max-width: 768.98px) {
        .p-top-message__list__text {
          margin-top: 5px;
          margin-bottom: 20px; } }

.p-top-carousel {
  width: 100%;
  position: relative; }
  .p-top-carousel__band, .p-top-carousel__band-02, .p-top-carousel__band-03, .p-top-carousel__band-04 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(1, 164, 164, 0.9);
    z-index: 10; }
    .p-top-carousel__band-02 {
      background-color: rgba(217, 99, 79, 0.9); }
    .p-top-carousel__band-03 {
      background-color: rgba(70, 171, 213, 0.9); }
    .p-top-carousel__band-04 {
      background-color: rgba(221, 239, 122, 0.9); }
    .p-top-carousel__band__inner {
      width: 100%;
      max-width: 1000px;
      display: flex;
      align-items: center;
      margin: 0 20px; }
    .p-top-carousel__band__text {
      font-size: 2.4rem;
      font-weight: bold;
      color: white;
      line-height: 32px;
      letter-spacing: 1.9px; }
      @media (min-width: 769px) {
        .p-top-carousel__band__text {
          letter-spacing: 1.9px; } }
      .p-top-carousel__band__text.anim-band-simple.-active {
        width: 285px; }
      .p-top-carousel__band__text__span {
        display: inline-block;
        border-left: solid 3px white;
        padding-left: 19px; }
        @media (min-width: 769px) {
          .p-top-carousel__band__text__span {
            letter-spacing: 1.9px;
            padding-left: 19px; } }

.p-top-application {
  background-color: #fff;
  padding: 45px 0 40px; }
  @media (min-width: 769px) {
    .p-top-application {
      display: flex;
      justify-content: center;
      padding: 95px 0 91px; } }
  .p-top-application__h2 {
    font-size: 2.4rem;
    font-weight: bold;
    color: #af0821;
    line-height: calc(37 / 24);
    border-bottom: solid 1px #af0821;
    padding-bottom: 19px;
    margin-bottom: 56px; }
    @media (min-width: 769px) {
      .p-top-application__h2 {
        font-size: 3.8rem;
        line-height: 1.5;
        padding-bottom: 24px; } }
  .p-top-application__h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 1px;
    margin-bottom: 9px; }
    @media (min-width: 769px) {
      .p-top-application__h3 {
        font-size: 2.4rem;
        margin-bottom: 16px; } }
    .p-top-application__h3__text {
      display: inline-block;
      border-bottom: solid 1px black; }
  .p-top-application__h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-top-application__h4 {
        font-size: 2rem;
        margin-bottom: 10px; } }
  @media (max-width: 768.98px) {
    .p-top-application__inner {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .p-top-application__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0 20px 0; } }
  @media (min-width: 769px) {
    .p-top-application__item {
      font-size: 1.6rem; } }
  .p-top-application__link {
    text-decoration: underline;
    transition: opacity 0.3s; }
    @media (max-width: 768.98px) {
      .p-top-application__link {
        display: block; } }
    @media (min-width: 769px) {
      .p-top-application__link {
        margin-left: 13px; }
        .p-top-application__link:hover {
          opacity: 0.75; } }
  @media (min-width: 769px) {
    .p-top-application__paragraph {
      font-size: 1.6rem; } }
  .p-top-application__intro {
    margin-bottom: 41px; }
    @media (min-width: 769px) {
      .p-top-application__intro {
        margin-bottom: 33px; } }
    .p-top-application__intro .p-top-application__paragraph {
      margin-bottom: 31px; }
    .p-top-application__intro .p-top-application__list {
      margin-bottom: 9px; }
      @media (min-width: 769px) {
        .p-top-application__intro .p-top-application__list {
          margin-bottom: 16px; } }
  .p-top-application__section.-overview {
    margin-bottom: 53px; }
    @media (min-width: 769px) {
      .p-top-application__section.-overview {
        margin-bottom: 57px; } }
    .p-top-application__section.-overview .p-top-application__paragraph {
      margin-bottom: 16px; }
      @media (min-width: 769px) {
        .p-top-application__section.-overview .p-top-application__paragraph {
          margin-bottom: 23px; } }
  .p-top-application__section.-detail {
    margin-bottom: 53px; }
    @media (min-width: 769px) {
      .p-top-application__section.-detail {
        margin-bottom: 53px; } }
  .p-top-application__section.-download {
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-top-application__section.-download {
        margin-bottom: 10px; } }
    .p-top-application__section.-download .p-top-application__h3 {
      line-height: 1.4;
      margin-bottom: 21px; }
  .p-top-application__sub-section {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-top-application__sub-section {
        margin-bottom: 22px; } }
    .p-top-application__sub-section .p-top-application__h4 {
      margin-bottom: 6px; }
      @media (min-width: 769px) {
        .p-top-application__sub-section .p-top-application__h4 {
          margin-bottom: 6px; } }
    @media (max-width: 768.98px) {
      .p-top-application__sub-section.-document {
        margin-bottom: 26px; } }
    .p-top-application__sub-section.-briefing .p-top-application__paragraph {
      margin-bottom: 29px; }
      @media (min-width: 769px) {
        .p-top-application__sub-section.-briefing .p-top-application__paragraph {
          margin-bottom: 32px; } }
  @media (min-width: 769px) {
    .p-top-application__schedule-item {
      font-size: 1.6rem; } }
  .p-top-application__table {
    width: 100%;
    border-collapse: collapse; }
    .p-top-application__table th,
    .p-top-application__table td {
      border: solid 1px #000;
      vertical-align: middle;
      padding: 20px; }
      @media (max-width: 768.98px) {
        .p-top-application__table th,
        .p-top-application__table td {
          padding: 5px;
          word-break: break-all; } }
    .p-top-application__table th {
      text-align: center;
      font-size: 1.8rem;
      background-color: #e6e6e6;
      font-weight: normal; }
      @media (max-width: 768.98px) {
        .p-top-application__table th {
          font-size: 1.4rem; } }
    .p-top-application__table td {
      font-size: 1.6rem; }
      @media (max-width: 768.98px) {
        .p-top-application__table td {
          font-size: 1.2rem; } }

.p-top-end-message {
  display: flex;
  justify-content: center;
  padding-top: 13px; }
  @media (min-width: 769px) {
    .p-top-end-message {
      padding-top: 25px; } }
  .p-top-end-message__text {
    width: 100%;
    max-width: 1000px;
    font-size: 1.2rem;
    margin: 0 20px; }
    @media (min-width: 769px) {
      .p-top-end-message__text {
        font-size: 1.6rem; } }

.p-top-voice {
  padding-top: 40px;
  padding-bottom: 70px; }
  @media (min-width: 769px) {
    .p-top-voice {
      display: flex;
      flex-direction: column;
      padding-top: 80px;
      padding-bottom: 140px; } }
  .p-top-voice__h3 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(37 / 24); }
    @media (min-width: 769px) {
      .p-top-voice__h3 {
        width: 416px;
        font-size: 3.8rem;
        line-height: calc(58 / 38); } }
  .p-top-voice__section {
    width: 100%;
    padding: 0 20px 60px; }
    @media (min-width: 769px) {
      .p-top-voice__section {
        width: 100%;
        max-width: 1000px;
        align-self: center;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 20px;
        padding-bottom: 30px; } }
    @media (max-width: 768.98px) {
      .p-top-voice__section {
        padding-bottom: 0; } }
    .p-top-voice__section.-section01 {
      padding-bottom: 56px; }
      @media (min-width: 769px) {
        .p-top-voice__section.-section01 {
          padding-bottom: 154px; } }
    .p-top-voice__section.-section02 {
      padding-top: 29px;
      padding-bottom: 63px; }
      @media (min-width: 769px) {
        .p-top-voice__section.-section02 {
          padding: 75px 20px 93px; } }
  .p-top-voice__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 90px; }
    @media (max-width: 768.98px) {
      .p-top-voice__body {
        padding-top: 40px;
        align-items: center;
        justify-content: center; } }
  @media (min-width: 769px) {
    .p-top-voice__wrapper {
      width: 710px; } }
  .p-top-voice__image {
    width: 180px; }
    @media (max-width: 768.98px) {
      .p-top-voice__image {
        margin-bottom: 20px; } }
  .p-top-voice__text:not(:last-of-type) {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-top-voice__text:not(:last-of-type) {
        margin-bottom: 36px; } }
  .p-top-voice__text dt {
    font-size: 20px;
    line-height: 2;
    margin-bottom: 10px;
    float: left; }
    @media (max-width: 768.98px) {
      .p-top-voice__text dt {
        float: none;
        font-size: 18px; } }
  .p-top-voice__text dd {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 80px;
    position: relative;
    padding-left: 30px;
    margin-left: auto;
    float: right; }
    @media (max-width: 768.98px) {
      .p-top-voice__text dd {
        font-size: 14px;
        margin-bottom: 40px;
        float: none;
        text-align: right;
        line-height: 1.5; } }
    .p-top-voice__text dd::before {
      content: '';
      position: absolute;
      background: #af0821;
      height: 1px;
      width: 24px;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .p-top-voice__list {
    padding-top: 27px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column; }
    .p-top-voice__list__inner {
      width: 100%;
      padding: 0 20px 80px; }
      @media (min-width: 769px) {
        .p-top-voice__list__inner {
          width: 100%;
          max-width: 1000px;
          align-self: center;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0 20px; } }
    .p-top-voice__list__text {
      font-size: 1.5rem;
      margin-top: 15px;
      margin-bottom: 60px;
      text-align: center; }

.p-top-advance {
  padding-top: 40px;
  padding-bottom: 60px; }
  @media (min-width: 769px) {
    .p-top-advance {
      display: flex;
      flex-direction: column;
      padding-top: 108px;
      padding-bottom: 140px; } }
  .p-top-advance__h2 {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    color: #af0821;
    line-height: 28px;
    letter-spacing: 2.4px; }
    @media (min-width: 769px) {
      .p-top-advance__h2 {
        font-size: 2.4rem;
        line-height: 28px;
        letter-spacing: 2.4px; } }
    .p-top-advance__h2.anim-band-simple.-active {
      width: 155px; }
    .p-top-advance__h2__wrapper {
      align-self: center;
      width: 100%;
      max-width: 1000px;
      padding: 0 20px;
      margin-bottom: 29px; }
      @media (min-width: 769px) {
        .p-top-advance__h2__wrapper {
          margin-bottom: 64px; } }
    .p-top-advance__h2__text {
      display: block;
      border-left: solid 3px #af0821;
      padding-left: 18px; }
      @media (min-width: 769px) {
        .p-top-advance__h2__text {
          padding-left: 19px; } }
  .p-top-advance__h3 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(37 / 24); }
    @media (max-width: 768.98px) {
      .p-top-advance__h3 {
        margin-bottom: 25px; } }
    @media (min-width: 769px) {
      .p-top-advance__h3 {
        width: 416px;
        font-size: 3.8rem;
        line-height: calc(58 / 38);
        padding-top: 4px; } }
  .p-top-advance__section {
    width: 100%;
    padding: 0 20px; }
    @media (min-width: 769px) {
      .p-top-advance__section {
        width: 100%;
        max-width: 1000px;
        align-self: center;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 20px; } }
    .p-top-advance__section.-section01 {
      padding-bottom: 56px; }
      @media (min-width: 769px) {
        .p-top-advance__section.-section01 {
          padding-bottom: 154px; } }
    .p-top-advance__section.-section02 {
      padding-top: 29px;
      padding-bottom: 63px; }
      @media (min-width: 769px) {
        .p-top-advance__section.-section02 {
          padding: 75px 20px 93px; } }
  @media (min-width: 769px) {
    .p-top-advance__body {
      width: 480px; } }
  .p-top-advance__text:not(:last-of-type) {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-top-advance__text:not(:last-of-type) {
        margin-bottom: 36px; } }
  .p-top-advance__list {
    padding-top: 27px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column; }
    @media (min-width: 769px) {
      .p-top-advance__list {
        padding-bottom: 120px;
        padding-top: 120px; } }
    .p-top-advance__list__inner {
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap; }
      @media (min-width: 769px) {
        .p-top-advance__list__inner {
          width: 745px;
          max-width: 1000px;
          align-self: center;
          justify-content: space-between;
          margin: 0 20px; } }
    .p-top-advance__list__item {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: center; }
      .p-top-advance__list__item img {
        width: 140px; }
      @media (min-width: 769px) {
        .p-top-advance__list__item {
          width: 140px;
          margin-bottom: 0; }
          .p-top-advance__list__item img {
            width: 100%; } }
      .p-top-advance__list__item-03 {
        width: 100%;
        margin-bottom: 0;
        display: flex;
        justify-content: center; }
        .p-top-advance__list__item-03 img {
          width: 164px; }
        @media (min-width: 769px) {
          .p-top-advance__list__item-03 {
            width: 164px; }
            .p-top-advance__list__item-03 img {
              width: 100%; } }

.p-top-galley {
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto; }
  @media (min-width: 769px) {
    .p-top-galley {
      padding-top: 80px; } }

#gallery {
  width: 100%;
  margin: 0 auto;
  /* 中央寄せ */
  position: relative;
  overflow: hidden; }

#gallery li {
  max-width: 150px; }
  @media (min-width: 769px) {
    #gallery li {
      max-width: 300px;
      padding: 5px; } }

#gallery li img {
  max-width: 100%;
  height: auto; }

.p-report-overview {
  background-color: #fff;
  padding: 45px 0 40px; }
  @media (min-width: 769px) {
    .p-report-overview {
      display: flex;
      justify-content: center;
      padding: 95px 0 91px; } }
  .p-report-overview__h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 1px;
    margin-bottom: 9px;
    position: relative;
    padding-left: 32px;
    margin-top: 30px; }
    @media (min-width: 769px) {
      .p-report-overview__h3 {
        font-size: 2.4rem;
        margin-bottom: 16px;
        margin-top: 60px; } }
    .p-report-overview__h3::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 0;
      top: 0; }
    .p-report-overview__h3.__01::before {
      background: url(/assets/img/report/num_icon01.png); }
    .p-report-overview__h3.__02::before {
      background: url(/assets/img/report/num_icon02.png); }
    .p-report-overview__h3.__03::before {
      background: url(/assets/img/report/num_icon03.png); }
    .p-report-overview__h3.__04::before {
      background: url(/assets/img/report/num_icon04.png); }
    .p-report-overview__h3__text {
      display: inline-block; }
  .p-report-overview__table {
    width: 100%;
    border-collapse: collapse; }
    .p-report-overview__table th,
    .p-report-overview__table td {
      border: solid 1px #000;
      vertical-align: middle;
      padding: 20px; }
      @media (max-width: 768.98px) {
        .p-report-overview__table th,
        .p-report-overview__table td {
          padding: 5px;
          word-break: break-all; } }
    .p-report-overview__table th {
      text-align: center;
      font-size: 1.8rem;
      background-color: #e6e6e6;
      font-weight: normal; }
      @media (max-width: 768.98px) {
        .p-report-overview__table th {
          font-size: 1.4rem; } }
      .p-report-overview__table th.-time {
        width: 26%; }
    .p-report-overview__table td {
      font-size: 1.6rem; }
      @media (max-width: 768.98px) {
        .p-report-overview__table td {
          font-size: 1.2rem; } }
      .p-report-overview__table td.-date {
        text-align: center;
        font-size: 1.8rem; }
        @media (max-width: 768.98px) {
          .p-report-overview__table td.-date {
            font-size: 1.4rem; } }
  .p-report-overview__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 90px; }
    @media (max-width: 768.98px) {
      .p-report-overview__list {
        margin-top: 40px; } }
    .p-report-overview__list__item {
      width: 300px;
      margin-bottom: 30px;
      text-align: center; }
      .p-report-overview__list__item.__01 {
        width: 630px; }
      @media (max-width: 768.98px) {
        .p-report-overview__list__item {
          width: 100%;
          margin-bottom: 15px; } }
    .p-report-overview__list.__past .p-report-overview__list__item {
      width: 480px; }
      .p-report-overview__list.__past .p-report-overview__list__item.__01 {
        width: 100%; }
    .p-report-overview__list__caption {
      font-size: 1.5rem;
      margin-top: 4px; }
      @media (max-width: 768.98px) {
        .p-report-overview__list__caption {
          font-size: 1.2rem; } }
  .p-report-overview__h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-report-overview__h4 {
        font-size: 2rem;
        margin-bottom: 10px; } }
  @media (max-width: 768.98px) {
    .p-report-overview__inner {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .p-report-overview__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0 20px 0; } }
  @media (min-width: 769px) {
    .p-report-overview__item {
      font-size: 1.6rem; } }
  .p-report-overview__link {
    text-decoration: underline;
    transition: opacity 0.3s; }
    @media (max-width: 768.98px) {
      .p-report-overview__link {
        display: block; } }
    @media (min-width: 769px) {
      .p-report-overview__link {
        margin-left: 13px; }
        .p-report-overview__link:hover {
          opacity: 0.75; } }
  @media (min-width: 769px) {
    .p-report-overview__paragraph {
      font-size: 1.6rem; } }
  .p-report-overview__intro {
    margin-bottom: 41px; }
    @media (min-width: 769px) {
      .p-report-overview__intro {
        margin-bottom: 33px; } }
    .p-report-overview__intro .p-top-application__paragraph {
      margin-bottom: 31px; }
    .p-report-overview__intro .p-top-application__list {
      margin-bottom: 9px; }
      @media (min-width: 769px) {
        .p-report-overview__intro .p-top-application__list {
          margin-bottom: 16px; } }
  .p-report-overview__section.-overview .p-top-application__paragraph {
    margin-bottom: 16px; }
    @media (min-width: 769px) {
      .p-report-overview__section.-overview .p-top-application__paragraph {
        margin-bottom: 23px; } }
  .p-report-overview__section.-detail {
    margin-bottom: 53px; }
    @media (min-width: 769px) {
      .p-report-overview__section.-detail {
        margin-bottom: 53px; } }
  .p-report-overview__section.-download {
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-report-overview__section.-download {
        margin-bottom: 10px; } }
    .p-report-overview__section.-download .p-top-application__h3 {
      line-height: 1.4;
      margin-bottom: 21px; }
  .p-report-overview__sub-section {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-report-overview__sub-section {
        margin-bottom: 22px; } }
    .p-report-overview__sub-section .p-top-application__h4 {
      margin-bottom: 6px; }
      @media (min-width: 769px) {
        .p-report-overview__sub-section .p-top-application__h4 {
          margin-bottom: 6px; } }
    @media (max-width: 768.98px) {
      .p-report-overview__sub-section.-document {
        margin-bottom: 26px; } }
    .p-report-overview__sub-section.-briefing .p-top-application__paragraph {
      margin-bottom: 29px; }
      @media (min-width: 769px) {
        .p-report-overview__sub-section.-briefing .p-top-application__paragraph {
          margin-bottom: 32px; } }
  @media (min-width: 769px) {
    .p-report-overview__schedule-item {
      font-size: 1.6rem; } }
  @media (min-width: 769px) {
    .p-report-overview__dl {
      font-size: 1.6rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap; } }
  @media (min-width: 769px) {
    .p-report-overview__dl dt {
      width: 20%; } }
  .p-report-overview__dl dd {
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-report-overview__dl dd {
        width: 80%; } }

.p-report-theme {
  background-color: #fff;
  padding: 0 0 20px; }
  @media (min-width: 769px) {
    .p-report-theme {
      display: flex;
      justify-content: center;
      padding: 120px 0 40px; } }
  .p-report-theme__h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 1px;
    margin-bottom: 9px;
    position: relative;
    padding-left: 32px;
    margin-top: 30px; }
    @media (min-width: 769px) {
      .p-report-theme__h3 {
        font-size: 2.4rem;
        margin-bottom: 16px;
        margin-top: 60px; } }
    .p-report-theme__h3::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 0;
      top: 0; }
    .p-report-theme__h3.__01::before {
      background: url(/assets/img/report/num_icon01.png); }
    .p-report-theme__h3.__02::before {
      background: url(/assets/img/report/num_icon02.png); }
    .p-report-theme__h3.__03::before {
      background: url(/assets/img/report/num_icon03.png); }
    .p-report-theme__h3.__04::before {
      background: url(/assets/img/report/num_icon04.png); }
    .p-report-theme__h3__text {
      display: inline-block; }
  .p-report-theme__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px; }
    .p-report-theme__table th,
    .p-report-theme__table td {
      border: solid 1px #000;
      vertical-align: middle;
      padding: 20px; }
      @media (max-width: 768.98px) {
        .p-report-theme__table th,
        .p-report-theme__table td {
          padding: 10px;
          display: block;
          width: 100%; } }
    .p-report-theme__table th {
      text-align: center;
      font-size: 1.8rem;
      font-weight: normal;
      width: 16.666%; }
      @media (max-width: 768.98px) {
        .p-report-theme__table th {
          font-size: 1.6rem;
          display: block;
          width: 100%; } }
    .p-report-theme__table td {
      font-size: 1.6rem; }
      @media (max-width: 768.98px) {
        .p-report-theme__table td {
          font-size: 1.4rem; } }
  .p-report-theme__list {
    margin-top: 30px;
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 100px; }
    @media (max-width: 768.98px) {
      .p-report-theme__list {
        margin-bottom: 40px; } }
    .p-report-theme__list__item {
      text-indent: -2.8em;
      padding-left: 3.3em; }
      .p-report-theme__list__item::before {
        counter-increment: item;
        content: counter(item) ".";
        padding-right: 2em; }
  .p-report-theme__h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-report-theme__h4 {
        font-size: 2rem;
        margin-bottom: 10px; } }
  @media (max-width: 768.98px) {
    .p-report-theme__inner {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .p-report-theme__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0 20px 0; } }
  @media (min-width: 769px) {
    .p-report-theme__item {
      font-size: 1.6rem; } }
  .p-report-theme__link {
    text-decoration: underline;
    transition: opacity 0.3s; }
    @media (max-width: 768.98px) {
      .p-report-theme__link {
        display: block; } }
    @media (min-width: 769px) {
      .p-report-theme__link {
        margin-left: 13px; }
        .p-report-theme__link:hover {
          opacity: 0.75; } }
  @media (min-width: 769px) {
    .p-report-theme__paragraph {
      font-size: 1.6rem; } }
  .p-report-theme__intro {
    margin-bottom: 41px; }
    @media (min-width: 769px) {
      .p-report-theme__intro {
        margin-bottom: 33px; } }
    .p-report-theme__intro .p-top-application__paragraph {
      margin-bottom: 31px; }
    .p-report-theme__intro .p-top-application__list {
      margin-bottom: 9px; }
      @media (min-width: 769px) {
        .p-report-theme__intro .p-top-application__list {
          margin-bottom: 16px; } }
  .p-report-theme__section.-overview .p-top-application__paragraph {
    margin-bottom: 16px; }
    @media (min-width: 769px) {
      .p-report-theme__section.-overview .p-top-application__paragraph {
        margin-bottom: 23px; } }
  .p-report-theme__section.-detail {
    margin-bottom: 53px; }
    @media (min-width: 769px) {
      .p-report-theme__section.-detail {
        margin-bottom: 53px; } }
  .p-report-theme__section.-download {
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-report-theme__section.-download {
        margin-bottom: 10px; } }
    .p-report-theme__section.-download .p-top-application__h3 {
      line-height: 1.4;
      margin-bottom: 21px; }
  .p-report-theme__sub-section {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-report-theme__sub-section {
        margin-bottom: 22px; } }
    .p-report-theme__sub-section .p-top-application__h4 {
      margin-bottom: 6px; }
      @media (min-width: 769px) {
        .p-report-theme__sub-section .p-top-application__h4 {
          margin-bottom: 6px; } }
    @media (max-width: 768.98px) {
      .p-report-theme__sub-section.-document {
        margin-bottom: 26px; } }
    .p-report-theme__sub-section.-briefing .p-top-application__paragraph {
      margin-bottom: 29px; }
      @media (min-width: 769px) {
        .p-report-theme__sub-section.-briefing .p-top-application__paragraph {
          margin-bottom: 32px; } }
  @media (min-width: 769px) {
    .p-report-theme__schedule-item {
      font-size: 1.6rem; } }

.p-report-voice {
  background-color: #fff;
  padding: 40px 0 20px; }
  @media (min-width: 769px) {
    .p-report-voice {
      display: flex;
      justify-content: center;
      padding: 120px 0 40px; } }
  .p-report-voice__list {
    margin-top: 30px;
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 100px; }
    .p-report-voice__list dt {
      font-size: 20px;
      line-height: 2;
      margin-bottom: 10px;
      float: left; }
      @media (max-width: 768.98px) {
        .p-report-voice__list dt {
          float: none;
          font-size: 18px; } }
    .p-report-voice__list dd {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 80px;
      position: relative;
      padding-left: 30px;
      margin-left: auto;
      float: right; }
      @media (max-width: 768.98px) {
        .p-report-voice__list dd {
          font-size: 14px;
          margin-bottom: 40px;
          float: none;
          text-align: right;
          line-height: 1.5; } }
      .p-report-voice__list dd::before {
        content: '';
        position: absolute;
        background: #af0821;
        height: 1px;
        width: 24px;
        left: 0;
        top: 50%;
        transform: translateY(-50%); }
  @media (max-width: 768.98px) {
    .p-report-voice__inner {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .p-report-voice__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0 20px 0; } }

.p-advance-training {
  background-color: #fff;
  padding: 45px 0 40px; }
  @media (min-width: 769px) {
    .p-advance-training {
      display: flex;
      justify-content: center;
      padding: 95px 0 91px; } }
  .p-advance-training__h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 1px;
    margin-bottom: 9px;
    margin-top: 30px; }
    @media (min-width: 769px) {
      .p-advance-training__h3 {
        font-size: 2.4rem;
        margin-bottom: 16px;
        margin-top: 60px; } }
    .p-advance-training__h3__text {
      display: inline-block;
      text-decoration: underline;
      line-height: 1.4; }
  .p-advance-training__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px; }
    @media (max-width: 768.98px) {
      .p-advance-training__table tr {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        width: 100%;
        line-height: 1.5; } }
    .p-advance-training__table th,
    .p-advance-training__table td {
      border: solid 1px #000;
      vertical-align: middle;
      padding: 15px; }
      @media (max-width: 768.98px) {
        .p-advance-training__table th,
        .p-advance-training__table td {
          padding: 5px;
          word-break: break-all; } }
    .p-advance-training__table th {
      text-align: center;
      font-size: 1.8rem;
      background-color: #e6e6e6;
      font-weight: normal; }
      @media (max-width: 768.98px) {
        .p-advance-training__table th {
          font-size: 1.4rem;
          text-align: left; }
          .p-advance-training__table th.__00 {
            display: none; } }
      .p-advance-training__table th.__01 {
        width: 26%; }
        @media (max-width: 768.98px) {
          .p-advance-training__table th.__01 {
            width: 30%; } }
      .p-advance-training__table th.__02 {
        width: 37.5%; }
        @media (max-width: 768.98px) {
          .p-advance-training__table th.__02 {
            width: 40%; } }
    .p-advance-training__table td {
      font-size: 1.6rem; }
      @media (max-width: 768.98px) {
        .p-advance-training__table td {
          font-size: 1.2rem;
          display: block;
          width: 100%; }
          .p-advance-training__table td.__01 {
            width: 30%; }
          .p-advance-training__table td.__02 {
            width: 40%; } }
      .p-advance-training__table td .fwb {
        font-weight: bold; }
    .p-advance-training__table .-date {
      text-align: center;
      font-size: 1.8rem; }
      @media (max-width: 768.98px) {
        .p-advance-training__table .-date {
          font-size: 1.4rem;
          display: block;
          width: 100%; } }
  .p-advance-training__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 90px;
    width: 745px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 768.98px) {
      .p-advance-training__list {
        margin-top: 40px; } }
    .p-advance-training__list__item {
      width: 140px;
      margin-bottom: 30px;
      text-align: center; }
      @media (max-width: 768.98px) {
        .p-advance-training__list__item {
          width: 100%;
          margin-bottom: 15px; }
          .p-advance-training__list__item img {
            width: 140px;
            margin-left: auto;
            margin-right: auto; } }
      .p-advance-training__list__item-03 {
        width: 164px; }
        @media (max-width: 768.98px) {
          .p-advance-training__list__item-03 {
            width: 100%; }
            .p-advance-training__list__item-03 img {
              width: 164px;
              margin-left: auto;
              margin-right: auto; } }
  .p-advance-training__h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-advance-training__h4 {
        font-size: 2rem;
        margin-bottom: 10px; } }
  @media (max-width: 768.98px) {
    .p-advance-training__inner {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .p-advance-training__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0 20px 0; } }
  @media (min-width: 769px) {
    .p-advance-training__item {
      font-size: 1.6rem; } }
  .p-advance-training__link {
    text-decoration: underline;
    transition: opacity 0.3s; }
    @media (max-width: 768.98px) {
      .p-advance-training__link {
        display: block; } }
    @media (min-width: 769px) {
      .p-advance-training__link {
        margin-left: 13px; }
        .p-advance-training__link:hover {
          opacity: 0.75; } }
  @media (min-width: 769px) {
    .p-advance-training__paragraph {
      font-size: 1.6rem; } }
  .p-advance-training__intro {
    margin-bottom: 41px; }
    @media (min-width: 769px) {
      .p-advance-training__intro {
        margin-bottom: 33px; } }
    .p-advance-training__intro .p-top-application__paragraph {
      margin-bottom: 31px; }
    .p-advance-training__intro .p-top-application__list {
      margin-bottom: 9px; }
      @media (min-width: 769px) {
        .p-advance-training__intro .p-top-application__list {
          margin-bottom: 16px; } }
  .p-advance-training__section.-overview .p-top-application__paragraph {
    margin-bottom: 16px; }
    @media (min-width: 769px) {
      .p-advance-training__section.-overview .p-top-application__paragraph {
        margin-bottom: 23px; } }
  .p-advance-training__section.-detail {
    margin-bottom: 53px; }
    @media (min-width: 769px) {
      .p-advance-training__section.-detail {
        margin-bottom: 53px; } }
  .p-advance-training__section.-download {
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .p-advance-training__section.-download {
        margin-bottom: 10px; } }
    .p-advance-training__section.-download .p-top-application__h3 {
      line-height: 1.4;
      margin-bottom: 21px; }
  .p-advance-training__sub-section {
    margin-bottom: 28px; }
    @media (min-width: 769px) {
      .p-advance-training__sub-section {
        margin-bottom: 22px; } }
    .p-advance-training__sub-section .p-top-application__h4 {
      margin-bottom: 6px; }
      @media (min-width: 769px) {
        .p-advance-training__sub-section .p-top-application__h4 {
          margin-bottom: 6px; } }
    @media (max-width: 768.98px) {
      .p-advance-training__sub-section.-document {
        margin-bottom: 26px; } }
    .p-advance-training__sub-section.-briefing .p-top-application__paragraph {
      margin-bottom: 29px; }
      @media (min-width: 769px) {
        .p-advance-training__sub-section.-briefing .p-top-application__paragraph {
          margin-bottom: 32px; } }
  @media (min-width: 769px) {
    .p-advance-training__schedule-item {
      font-size: 1.6rem; } }

/* animations */
.anim-band__inner {
  display: inline-flex;
  position: relative;
  overflow: hidden; }
  .anim-band__inner::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e4e7e8;
    transform: translateX(-101%); }

.anim-band__text {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.7s; }
  .anim-band__text.__app {
    width: 540px; }
    @media (max-width: 768.98px) {
      .anim-band__text.__app {
        width: auto; } }
  .anim-band__text.__adv {
    width: 530px; }
    @media (max-width: 768.98px) {
      .anim-band__text.__adv {
        width: auto;
        min-width: 300px; } }

.anim-band.-active .anim-band__inner::after {
  animation: band 1.4s cubic-bezier(0.395, 0.14, 0.6, 0.88); }

.anim-band.-active .anim-band__text {
  visibility: visible;
  opacity: 1; }

.anim-band.-times15 .anim-band__text {
  transition: all 0s 1.2s; }

.anim-band.-times15.-active .anim-band__inner::after {
  animation: band 2.4s cubic-bezier(0.395, 0.14, 0.6, 0.88); }

@keyframes band {
  0% {
    transform: translateX(-101%); }
  50% {
    transform: translateX(0); }
  100% {
    transform: translateX(101%); } }

.anim-band-simple {
  width: 0;
  position: relative;
  overflow: hidden;
  transition: width 0.75s;
  white-space: nowrap; }

.anim-slide-down {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.75s ease-in-out; }
  .anim-slide-down.-active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0); }
