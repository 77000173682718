/* =============================================
 * Main
 * ============================================= */

.l-main {
  flex-grow: 1;
  padding-bottom: 40px;

  @include media-breakpoint-up(md) {
    padding-bottom: 100px;
  }
}
