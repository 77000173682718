.c-download-btn {
  width: 100%;
  height: 61px;
  display: flex;
  align-items: center;
  position: relative;
  border: solid 2px #333;
  font-size: 1.5rem;
  padding: 0 50px 0 22px;
  transition: opacity 0.3s;

  @include media-breakpoint-up(md) {
    width: 280px;
    padding-left: 20px;
  }

  &:hover {
    opacity: 0.75;
  }

  &::after {
    display: block;
    content: "";
    width: 25px;
    height: 32px;
    position: absolute;
    top: calc(50% - 15px);
    right: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include media-breakpoint-up(md) {
      right: 15px;
    }
  }

  &.-doc::after {
    background-image: url("/assets/img/common/icon_doc.svg");
  }

  &.-pdf::after {
    background-image: url("/assets/img/common/icon_pdf.svg");
  }

  &__group {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .c-download-btn:not(:last-of-type) {
      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }
    }
  }
}
