.l-overlay {
  width: 100%;
  height: 200%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  visibility: hidden;
  opacity: 0;
  will-change: opacity;
  transition: all 0.3s;
  z-index: 10;

  body[data-is-visible-drawer="true"] & {
    visibility: visible;
    opacity: 1;
  }
}
