.l-header-nav {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(xl) {
      justify-content: flex-end;
      margin-right: 22px;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 60px;
    }
  }

  &__item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    position: relative;
    font-size: 1.4rem;
    padding-left: 40px;
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      background: #af0821;
      height: 1px;
      width: 34px;
      top: 50%;
      left: 0;
    }


    &:hover {
      opacity: 0.65;
    }
  }
}
