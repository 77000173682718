.p-top-carousel {
  width: 100%;
  position: relative;

  &__list {}

  &__item {}

  &__img {}

  &__band {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(1, 164, 164, 0.9);
    z-index: 10;

    &-02 {
      @extend .p-top-carousel__band;

      background-color: rgba(217, 99, 79, 0.9);
    }

    &-03 {
      @extend .p-top-carousel__band;

      background-color: rgba(70, 171, 213, 0.9);
    }

    &-04 {
      @extend .p-top-carousel__band;

      background-color: rgba(221, 239, 122, 0.9);
    }

    &__inner {
      width: 100%;
      max-width: $base-width;
      display: flex;
      align-items: center;
      margin: 0 20px;
    }

    &__text {
      font-size: 2.4rem;
      font-weight: bold;
      color: white;
      line-height: 32px;
      letter-spacing: 1.9px;

      @include media-breakpoint-up(md) {
        letter-spacing: 1.9px;
      }

      &.anim-band-simple.-active {
        width: 285px;
      }

      &__span {
        display: inline-block;
        border-left: solid 3px white;
        padding-left: 19px;

        @include media-breakpoint-up(md) {
          letter-spacing: 1.9px;
          padding-left: 19px;
        }
      }
    }
  }
}
