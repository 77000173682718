@charset "UTF-8";

/* google fonts */
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700|Roboto:400,700,700i&display=swap');

/* core */
@import "core/bootstrap.override";
@import 'core/variable';
@import 'core/mixin';
@import "core/base";

/* layouts */
@import "layout/header/index";
@import "layout/footer/index";
@import "layout/main";
@import "layout/drawer-menu";
@import "layout/overlay";
@import "layout/breadcrumb";
@import "layout/pageup";

/* components */
@import "components/download-btn";
@import "components/column";
@import "components/link-btn";
@import "components/top-ttl";
@import "components/heading";

/* pages */
@import "pages/top/index";
@import "pages/report/index";
@import "pages/advance/index";

/* animations */
@import "animations/band";
@import "animations/band-simple";
@import "animations/slide-down";
