.anim-band {

  &__inner {
    display: inline-flex;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $silver-color;
      transform: translateX(-101%);
    }
  }

  &__text {
    visibility: hidden;
    opacity: 0;
    transition: all 0s 0.7s;

    &.__app {
      width: 540px;

      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }

    &.__adv {
      width: 530px;

      @include media-breakpoint-down(sm) {
        width: auto;
        min-width: 300px;
      }
    }
  }

  &.-active {

    .anim-band__inner::after {
      animation: band 1.4s cubic-bezier(0.395, 0.14, 0.6, 0.88);
    }

    .anim-band__text {
      visibility: visible;
      opacity: 1;
    }
  }

  &.-times15 {

    .anim-band__text {
      transition: all 0s 1.2s;
    }

    &.-active {

      .anim-band__inner::after {
        animation: band 2.4s cubic-bezier(0.395, 0.14, 0.6, 0.88);
      }
    }
  }
}

@keyframes band {

  0% {
    transform: translateX(-101%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(101%);
  }
}
