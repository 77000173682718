.c-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  &-03__item {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 31.25%;
    }
  }
}
