.p-report-overview {
  background-color: #fff;
  padding: 45px 0 40px;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    padding: 95px 0 91px;
  }

  &__h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 1px;
    margin-bottom: 9px;
    position: relative;
    padding-left: 32px;
    margin-top: 30px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      margin-bottom: 16px;
      margin-top: 60px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 0;
      top: 0;
    }

    &.__01::before {
      background: url(/assets/img/report/num_icon01.png);
    }

    &.__02::before {
      background: url(/assets/img/report/num_icon02.png);
    }

    &.__03::before {
      background: url(/assets/img/report/num_icon03.png);
    }

    &.__04::before {
      background: url(/assets/img/report/num_icon04.png);
    }

    &__text {
      display: inline-block;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: solid 1px #000;
      vertical-align: middle;
      padding: 20px;

      @include media-breakpoint-down(sm) {
        padding: 5px;
        word-break: break-all;
      }
    }

    th {
      text-align: center;
      font-size: 1.8rem;
      background-color: #e6e6e6;
      font-weight: normal;

      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
      }

      &.-time {
        width: 26%;
      }
    }

    td {
      font-size: 1.6rem;

      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }

      &.-date {
        text-align: center;
        font-size: 1.8rem;

        @include media-breakpoint-down(sm) {
          font-size: 1.4rem;
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 90px;

    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }

    &__item {
      width: 300px;
      margin-bottom: 30px;
      text-align: center;

      &.__01 {
        width: 630px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    &.__past {

      .p-report-overview__list__item {
        width: 480px;

        &.__01 {
          width: 100%;
        }
      }
    }

    &__caption {
      font-size: 1.5rem;
      margin-top: 4px;

      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }
    }
  }

  &__h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  &__inner {
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: $base-width;
      margin: 0 20px 0;
    }
  }

  &__item {
    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }

  &__link {
    text-decoration: underline;
    transition: opacity 0.3s;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    @include media-breakpoint-up(md) {
      margin-left: 13px;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__paragraph {
    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }

  &__intro {
    margin-bottom: 41px;

    @include media-breakpoint-up(md) {
      margin-bottom: 33px;
    }

    .p-top-application__paragraph {
      margin-bottom: 31px;
    }

    .p-top-application__list {
      margin-bottom: 9px;

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }
  }

  &__section {

    &.-overview {

      .p-top-application__paragraph {
        margin-bottom: 16px;

        @include media-breakpoint-up(md) {
          margin-bottom: 23px;
        }
      }
    }

    &.-detail {
      margin-bottom: 53px;

      @include media-breakpoint-up(md) {
        margin-bottom: 53px;
      }
    }

    &.-download {
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 10px;
      }

      .p-top-application__h3 {
        line-height: 1.4;
        margin-bottom: 21px;
      }
    }
  }

  &__sub-section {
    margin-bottom: 28px;

    @include media-breakpoint-up(md) {
      margin-bottom: 22px;
    }

    .p-top-application__h4 {
      margin-bottom: 6px;

      @include media-breakpoint-up(md) {
        margin-bottom: 6px;
      }
    }

    &.-document {
      @include media-breakpoint-down(sm) {
        margin-bottom: 26px;
      }
    }

    &.-submit-to {}
    &.-qualification {}
    &.-period {}
    &.-capacity {}
    &.-cost {}

    &.-briefing {

      .p-top-application__paragraph {
        margin-bottom: 29px;

        @include media-breakpoint-up(md) {
          margin-bottom: 32px;
        }
      }
    }
  }

  &__schedule {

    &-list {}

    &-item {
      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
    }
  }

  &__dl {
    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    dt {
      @include media-breakpoint-up(md) {
        width: 20%;
      }
    }

    dd {
      @include media-breakpoint-up(md) {
        width: 80%;
      }

      margin-bottom: 10px;
    }
  }
}
