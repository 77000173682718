.c-link-btn {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.8rem;
  line-height: 1.3888;
  font-weight: bold;
  padding: 14px 30px 14px 14px;
  transition: opacity 0.3s;
  background: #af0821;
  color: #fff;
  text-align: center;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $base-width;
    padding: 0 20px;
  }

  &:hover {
    color: #fff;
    opacity: 0.65;
  }

  @include media-breakpoint-up(md) {
    width: 360px;
    padding-right: 30px;
  }

  &::before,
  &::after {
    display: block;
    content: "";
    position: absolute;
  }

  &::before {
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: width 0.3s;
    pointer-events: none;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &::after {
    width: 9px;
    height: 24px;
    top: 50%;
    right: 5%;
    background: url(/assets/img/common/icon_arrow-bottom.svg) no-repeat center;
    background-size: contain;
    transform: translateX(0) translateY(-50%) rotate(-90deg);

    @include media-breakpoint-up(md) {
      width: 6px;
      height: 16px;
      top: 50%;
      transition: transform 0.3s;
    }

    @include media-breakpoint-down(sm) {
    }
  }

  &:hover::before {
    width: 100%;
  }

  &:hover::after {
    @include media-breakpoint-up(md) {
      transform: translateX(7px) translateY(-50%) rotate(-90deg);
    }
  }
}
