/* bootstrapのutilitiesクラスだけ使用する */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 1025px,
  xl: 1281px
);

$theme-colors: (
  primary: #af0821
);

/* libs */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
