.p-report-voice {
  background-color: #fff;
  padding: 40px 0 20px;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    padding: 120px 0 40px;
  }

  &__list {
    margin-top: 30px;
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 100px;

    dt {
      font-size: 20px;
      line-height: 2;
      margin-bottom: 10px;
      float: left;

      @include media-breakpoint-down(sm) {
        float: none;
        font-size: 18px;
      }
    }

    dd {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 80px;
      position: relative;
      padding-left: 30px;
      margin-left: auto;
      float: right;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        margin-bottom: 40px;
        float: none;
        text-align: right;
        line-height: 1.5;
      }

      &::before {
        content: '';
        position: absolute;
        background: #af0821;
        height: 1px;
        width: 24px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__inner {
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: $base-width;
      margin: 0 20px 0;
    }
  }
}
