.anim-slide-down {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.75s ease-in-out;

  &.-active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
