.l-footer-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 267px;
  background-color: rgba(255, 255, 255, 0.8);

  @include media-breakpoint-down(sm) {
    padding: 33px 0 35px;
    margin: -134px 0 14px;
  }

  @include media-breakpoint-up(md) {
    align-items: center;
    width: $base-width;
    height: 75px;
    margin: 0 auto 20px;
  }

  &__list {
    @include media-breakpoint-down(sm) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &__item {
    position: relative;
    padding-left: 30px;

    @include media-breakpoint-up(md) {
      padding-left: 30px;

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }

    &::before {
      display: block;
      content: "";
      width: 24px;
      height: 1px;
      background-color: $theme-color;
      position: absolute;
      top: 50%;
      left: 0;

      @include media-breakpoint-up(md) {
        width: 24px;
      }
    }
  }

  &__link {
    font-size: 1.4rem;
    font-weight: bold;
    color: #000;
  }
}
