// ==============================================
// ブレイクポイント設定
// ==============================================
// これ以上の場合にPC表示になる
$breakpoint-pc: 768px;
$base-width: 1000px;

// ==============================================
// フォント設定
// ==============================================
// ベースのフォントサイズ 1rem = 10px
$base-font-size: 1.8rem;
$base-sp-font-size: 1.4rem;

// ベースのフォントカラー
$base-font-color: #333;

// ベースのリンクカラー
$base-link-color: #333;

// ベースの行間
$base-line-height: 2;

// ベースのフォント
$base-font-family: 'Roboto', 'Noto Sans JP', 'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3', 'Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;

// ==============================================
// 色設定
// ==============================================
// テーマカラー
$theme-color: #af0821;
$silver-color: #e4e7e8;
