.p-top-galley {
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 100%;
  max-width: $base-width;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding-top: 80px;
  }
}

#gallery {
  width: 100%;
  margin: 0 auto;   /* 中央寄せ */
  position: relative;
  overflow: hidden;
}

#gallery li {
  max-width: 150px;

  @include media-breakpoint-up(md) {
    max-width: 300px;
    padding: 5px;
  }
}

#gallery li img {
  max-width: 100%;
  height: auto;
}
