.l-footer-copyright {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;

  @include media-breakpoint-up(md) {
    font-size: 1.2rem;
  }
}
