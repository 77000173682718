.l-footer-kv {
  height: 384px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("/assets/img/common/footer-kv-sp.jpg");

  @include media-breakpoint-down(sm) {
    padding: 0 20px;
  }

  @include media-breakpoint-up(md) {
    height: 446px;
    background-image: url("/assets/img/common/footer-kv.jpg");
    background-size: auto 100%;
    background-color: black;
  }

  &__top-area {
    height: 174px;
    background-color: rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(md) {
      height: 260px;
      padding-top: 100px;
    }
  }

  &__bottom-area {
    height: 210px;
    background-color: rgba(175, 8, 8, 0.6);

    @include media-breakpoint-up(md) {
      height: 186px;
      padding-top: 40px;
    }
  }

  &__title {
    width: 698px;
    font-size: 6.9rem;
    font-weight: bold;
    font-style: italic;
    color: white;
    line-height: 1;
    text-shadow: 0 4px 4px rgba(255, 255, 255, 0.16);
    white-space: nowrap;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__img {}
  }

  &__annot {
    font-size: 1.2rem;
    color: white;

    @media screen and (max-width: 320px) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      text-align: center;
      padding: 0 20px;
    }
  }
}
