.l-header-hamburger-btn {
  width: 80px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__bar {
    width: 39px;
    height: 3px;
    position: relative;
    background-color: $theme-color;
    transition: all 0.3s;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: $theme-color;
      transition: all 0.3s;
    }

    &::before {
      top: -12px;
    }

    &::after {
      top: 12px;
    }
  }

  body[data-is-visible-drawer="true"] & {

    .l-header-hamburger-btn__bar {
      width: 48px;
      background-color: transparent !important;

      &::before {
        top: 0;
        transform: rotate(225deg);
      }

      &::after {
        top: 0;
        transform: rotate(-225deg);
      }
    }
  }
}
