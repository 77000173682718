.p-top-message {
  padding-top: 61px;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    padding-top: 108px;
  }

  &__h2 {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    color: $theme-color;
    line-height: 28px;
    letter-spacing: 2.4px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      line-height: 28px;
      letter-spacing: 2.4px;
    }

    &.anim-band-simple.-active {
      width: 155px;
    }

    &__wrapper {
      align-self: center;
      width: 100%;
      max-width: $base-width;
      padding: 0 20px;
      margin-bottom: 29px;

      @include media-breakpoint-up(md) {
        margin-bottom: 64px;
      }
    }

    &__text {
      display: block;
      border-left: solid 3px $theme-color;
      padding-left: 18px;

      @include media-breakpoint-up(md) {
        padding-left: 19px;
      }
    }
  }

  &__h3 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(37 / 24);

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
    }

    @include media-breakpoint-up(md) {
      width: 416px;
      font-size: 3.8rem;
      line-height: calc(58 / 38);
      padding-top: 4px;
    }
  }

  &__section {
    width: 100%;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: $base-width;
      align-self: center;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 20px;
    }

    &.-section01 {
      padding-bottom: 56px;

      @include media-breakpoint-up(md) {
        padding-bottom: 154px;
      }
    }

    &.-section02 {
      padding-top: 29px;

      @include media-breakpoint-up(md) {
        padding: 75px 20px 93px;
      }
    }
  }

  &__body {
    @include media-breakpoint-up(md) {
      width: 480px;
    }
  }

  &__text {

    &:not(:last-of-type) {
      margin-bottom: 28px;

      @include media-breakpoint-up(md) {
        margin-bottom: 36px;
      }
    }

    &.__small {
      font-size: 1.4rem;

      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }
    }
  }

  &__list {
    padding-top: 27px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      padding-bottom: 70px;
    }

    &__inner {
      width: 100%;
      padding: 0 20px 80px;

      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: $base-width;
        align-self: center;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 20px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 5px;
        padding-bottom: 40px;
      }
    }

    &__text {
      font-size: 1.5rem;
      margin-top: 15px;
      margin-bottom: 60px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
  }
}
