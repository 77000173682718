.l-breadcrumb {
  width: 100%;
  max-width: 1000px;
  margin: 10px auto 0;

  @include media-breakpoint-down(sm) {
    padding: 0 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 5px;

      &::after {
        content: ">";
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &__link {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}
